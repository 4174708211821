import React from 'react'
import ReactDOM from 'react-dom'
// import * as serviceWorker from './serviceWorker'
import swDev from 'swDev'

import { TEAppWrapper } from 'react-tec'
import { withScriptjs } from 'react-google-maps'

import App from './App'
import { theme, globalStyles } from './globalStyles'
import { LoadingProvider, TitleBarProvider, SideNavProvider } from './contexts'
import settings from './configs/settings'

import 'react-table/react-table.css'
import './index.css'
import './reactTEC.css'

//TODO: Implement StrictMode.
//
// - react-tec throws errors because it relies on styled components and some other unsafe components
//
const GoogleMapsAPIWrapper = withScriptjs((props) => props.children)

const Main = () => {
	return (
		<GoogleMapsAPIWrapper
			googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${settings.GOOGLE_MAP_API}&v=3.exp&libraries=geometry,drawing,places`}
			loadingElement={<div />}
		>
			<TEAppWrapper theme={theme} globalStyles={globalStyles}>
				<LoadingProvider>
					<TitleBarProvider>
						<SideNavProvider>
							<App />
						</SideNavProvider>
					</TitleBarProvider>
				</LoadingProvider>
			</TEAppWrapper>
		</GoogleMapsAPIWrapper>
	)
}

ReactDOM.render(<Main />, document.getElementById('root'))

swDev()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
