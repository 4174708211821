export const clearKioskCache = () => {
	console.log('attempting to delete kiosk cache')
	if (caches.has('kiosk')) {
		caches.delete('kiosk')
	}
	return () => {
		console.log('attempting to delete kiosk cache on unmount')
		if (caches.has('kiosk')) {
			caches.delete('kiosk')
		}
	}
}
