import React, { createContext, useState, useContext } from 'react'

export const SideNavContext = createContext()

export const SideNavProvider = (props) => {
	const [active, setActive] = useState(true)
	return (
		<SideNavContext.Provider value={{ active, setActive }}>
			{props.children}
		</SideNavContext.Provider>
	)
}

export const useSideBar = () => {
	const { active, setActive } = useContext(SideNavContext)
	return { active, setActive }
}
