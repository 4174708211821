export const displayPhoneNumber = (phoneNumber) => {
	if (!phoneNumber) {
		return null
	}

	//Remove "+1"
	let cleanedNumber = ''
	if (phoneNumber.charAt(0) === '+' && phoneNumber.charAt(1) === '1') {
		cleanedNumber = phoneNumber.slice(2)
	} else {
		cleanedNumber = ('' + phoneNumber).replace(/\D/g, '')
	}

	const numberParts = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/)
	return !numberParts ? null : '(' + numberParts[1] + ') ' + numberParts[2] + '-' + numberParts[3]
}

export const round = (number, numDecimalPlaces) => {
	return Math.round(number * 10 ** numDecimalPlaces) / 10 ** numDecimalPlaces
}

export const getColorBrightness = (hexCode) => {
	/*
	 * Calculate brightness value by HEX color (from https://gist.github.com/w3core/e3d9b5b6d69a3ba8671cc84714cca8a4)
	 * @param color (String) The color value as a 7 digit HEX code
	 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
	 */
	if (hexCode?.indexOf('#') === 0 && hexCode?.length === 7) {
		const m = hexCode.substr(1).match(/(\S{2})/g)
		if (m) {
			const r = parseInt(m[0], 16)
			const g = parseInt(m[1], 16)
			const b = parseInt(m[2], 16)
			return (r * 299 + g * 587 + b * 114) / 1000
		}
	}
}

export const isDarkColor = (hexCode) => {
	// undefined/invalid hexCode returns false (assuming it will render as white)
	const brightness = getColorBrightness(hexCode)
	return Boolean(brightness && brightness < 255 / 2)
}
