import moment from 'moment'

export const convertToDateTimeString = (epoch) => {
	if (!epoch) {
		return ''
	}

	return moment(epoch).format('ddd, MMM D, YYYY, h:mm A')
}
export const convertToDateString = (epoch) => {
	if (!epoch) {
		return ''
	}

	return moment(epoch).format('ddd, MMM D, YYYY')
}
export const convertToDateTimeShort = (epoch) => {
	if (!epoch) {
		return ''
	}

	return moment(epoch).format('M/D/YYYY, h:mm A')
}
export const convertToDateShort = (epoch) => {
	if (!epoch) {
		return ''
	}

	return moment(epoch).format('M/D/YYYY')
}
export const convertToTime = (epoch) => {
	if (!epoch) {
		return ''
	}

	return moment(epoch).format('h:mm A')
}
