import settings from 'configs/settings'

export const compareToVersion = (version, correctVersion) => {
	if (!version || !correctVersion) {
		return {}
	}
	const correctVersionParts = correctVersion.split('.')
	const versionParts = version.split('.')

	const major = Number(correctVersionParts[0]) - Number(versionParts[0])
	const minor = Number(correctVersionParts[1]) - Number(versionParts[1])
	const patch = Number(correctVersionParts[2]) - Number(versionParts[2])

	const behind =
		major > 0 || (major === 0 && minor > 0) || (major === 0 && minor === 0 && patch > 0) //Local version is behind input version
	const equal = major === 0 && minor === 0 && patch === 0 //Local Version is the same as input version
	const ahead =
		major > 0 || (major === 0 && minor < 0) || (major === 0 && minor === 0 && patch < 0) //Local version is ahead of input version - Should never happen for "currentVersion"

	return { behind, equal, ahead }
}
export const compareToLocalVersion = (serverVersion) => {
	const { VERSION: localVersion } = settings
	// console.log({ localVersion, serverVersion })
	return compareToVersion(localVersion, serverVersion)
}
