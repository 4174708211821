import firebase from 'firebase'

export const collectionToDataObject = (snapshot) => {
	if (!snapshot) {
		return snapshot
	}

	const data = {}
	snapshot.forEach((doc) => {
		data[doc.id] = doc.data()
	})
	return data
}
export const collectionToDataArray = (snapshot) => {
	if (!snapshot) {
		return snapshot
	}

	const data = []
	snapshot.forEach((doc) => {
		data.push({
			uid: doc.id,
			...doc.data(),
		})
	})
	return data
}
export const collectionToDataObjectAndArray = (snapshot) => {
	if (!snapshot) {
		return snapshot
	}

	const array = []
	const object = {}
	snapshot.forEach((doc) => {
		const data = doc.data()
		array.push({
			uid: doc.id,
			...data,
		})
		object[doc.id] = data
	})
	return { object, array }
}
export const collectionToDataTypes = (snapshot, types = ['object', 'array', 'set']) => {
	if (!snapshot) {
		return snapshot
	}

	const dataToReturn = {}

	const object = {}
	const saveObject = types.includes('object')
	const array = []
	const saveArray = types.includes('array')
	const set = new Set()
	const saveSet = types.includes('set')

	snapshot.forEach((doc) => {
		const data = doc.data()

		if (saveObject) {
			object[doc.id] = data
		}
		if (saveArray) {
			array.push({
				uid: doc.id,
				...data,
			})
		}
		if (saveSet) {
			set.add()
		}
	})
	return dataToReturn
}
export const docToDataObject = (doc) => {
	if (!doc || (doc && !doc.exists)) {
		return null
	}

	return {
		uid: doc.id,
		...doc.data(),
	}
}

export const batchLoadListOfUIDs = async (data) => {
	const { collection, uids } = data
	if (!(collection && uids && uids.length > 0)) {
		return
	}

	const requestArray = []
	let i = 0
	while (i <= uids.length) {
		requestArray.push(
			firebase
				.firestore()
				.collection(collection)
				.where(firebase.firestore.FieldPath.documentId(), 'in', uids.slice(i, i + 10))
				.get(),
		)
		i += 10
	}
	const snapshotGroupArray = await Promise.all(requestArray)
	return snapshotGroupArray.reduce(
		(dataArray, groupSnapshot) => [...dataArray, ...collectionToDataArray(groupSnapshot)],
		[],
	)
}

/*
	this is buggy, need to revisit
	1) duplicate data will be loaded
	2) need more control over stopping/starting the watchers
*/

// export const useManyUids = (collection, uids) => {
// 	const [dataObject, setDataObject] = useState({})

// 	console.log(collection, uids)

// 	useEffect(() => {
// 		if (!(collection && uids && uids.length > 0)) {
// 			return
// 		}
// 		const watcherArray = []
// 		let i = 0
// 		while (i <= uids.length) {
// 			watcherArray.push(
// 				firebase
// 					.firestore()
// 					.collection(collection)
// 					.where(firebase.firestore.FieldPath.documentId(), 'in', uids.slice(i, i + 10))
// 					.onSnapshot(
// 						(snapshot) => {
// 							let data = collectionToData(snapshot)
// 							if (data && data.length > 0) {
// 								setDataObject((d) => [...d, data])
// 							}
// 						},
// 						(e) => {
// 							console.log(e)
// 							setDataObject([])
// 						},
// 					),
// 			)
// 			i += 10
// 		}
// 		return () => {
// 			watcherArray.map((el) => el())
// 			setDataObject({})
// 		}
// 	}, [collection, uids])

// 	return dataObject
// }

export const getDocByUid = async (collection, uid) => {
	let doc = undefined
	if (!collection || !uid) {
		return doc
	}
	const fbData = await firebase.firestore().collection(collection).doc(uid)
	let dataArray = collectionToDataArray(fbData)
	if (dataArray.length === 1) {
		doc = dataArray[0]
	}
	return doc
}

//
// Convert Firebase Error Messages To Custom Ones
//
export const determineAuthErrorMessage = (error) => {
	switch (error.code) {
		case 'auth/invalid-email':
			return {
				alertTitle: 'Invalid Email Format',
				alertMessage: 'Please provide a valid email address and try again.',
				alertIsVisible: true,
			}
		case 'auth/wrong-password':
			return {
				alertTitle: 'Invalid Password',
				alertMessage: 'Please provide a valid password and try again.',
				alertIsVisible: true,
			}
		case 'auth/network-request-failed':
			return {
				alertTitle: 'Network Request Failed',
				alertMessage: 'Please check your network connection and try again.',
				alertIsVisible: true,
			}
		case 'auth/user-not-found':
			return {
				alertTitle: 'Error Signing In',
				alertMessage: 'Please check your email and password and try again.',
				alertIsVisible: true,
			}
		case 'auth/email-already-in-use':
			return {
				alertTitle: 'Error Registering',
				alertMessage: 'There is already a user with that email address.',
				alertIsVisible: true,
			}
		case 'auth/invalid-action-code':
			return {
				alertTitle: 'Invalid Code',
				alertMessage: 'The code has either expired or already been used.',
				alertIsVisible: true,
			}
		default:
			console.log('Uncaught error', error)
			return {
				alertTitle: 'Authentication Failed',
				alertMessage: error.message,
				alertIsVisible: true,
			}
	}
}
export const determineForgotPasswordErrorMessage = (error) => {
	switch (error.code) {
		case 'auth/invalid-email':
			return {
				alertTitle: 'Invalid Email Format',
				alertMessage: 'Please provide a valid email address and try again.',
				alertIsVisible: true,
			}
		case 'auth/user-not-found':
			return {
				alertTitle: 'No Matching Email',
				alertMessage: 'Please check your email and try again.',
				alertIsVisible: true,
			}
		case 'auth/network-request-failed':
			return {
				alertTitle: 'Network Request Failed',
				alertMessage: 'Please check your network connection and try again.',
				alertIsVisible: true,
			}
		default:
			console.log('Uncaught error', error)
			return {
				alertTitle: 'Error Sending Email',
				alertMessage:
					'Failed to send password reset email. Check that you are entereing a valid email address and try again.',
				alertIsVisible: true,
			}
	}
}
export const determinePasswordResetErrorMessage = (error) => {
	switch (error.code) {
		case 'auth/weak-password':
			return {
				alertTitle: 'Invalid Password',
				alertMessage: 'Password must be at least 6 characters long.',
				alertIsVisible: true,
			}
		default:
			console.log('Uncaught error', error)
			return {
				alertTitle: 'Error Updating Password',
				alertMessage:
					'Check that your password is more then 6 characters and matches the confirm password.',
				alertIsVisible: true,
			}
	}
}
