import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import settings from 'configs/settings'

export const apiRequest = async (
	method,
	path,
	data,
	withToken = true,
	headers = {},
	logToConsole,
) => {
	const { API_BASE_URL, apiDebugging } = settings

	let logsEnabled = apiDebugging
	if (typeof logToConsole === 'boolean') {
		logsEnabled = logToConsole
	}

	let url = API_BASE_URL + '/'
	if (path) {
		url += path
	}

	if (withToken) {
		const { uid } = firebase.auth().currentUser
		const token = await firebase.auth().currentUser.getIdToken()
		headers.uid = uid
		headers.token = token
	}

	if (logsEnabled) {
		console.log(`API REQUEST - ${path}:`, { method, url, headers, data })
	}

	const response = await axios({ method, url, headers, data })

	if (logsEnabled && response) {
		console.log(`API RESPONSE - ${path}:`, response)
	}

	return response
}
