const LOCAL = {
	FIREBASE_API_KEY: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
	FIREBASE_AUTH_DOMAIN: 'trackit-954bb.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://trackit-954bb.firebaseio.com',
	FIREBASE_PROJECT_ID: 'trackit-954bb',
	FIREBASE_STORAGE_BUCKET: 'trackit-954bb.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '799165776336',

	BASE_URL: 'http://localhost:3020',
	API_BASE_URL: 'http://127.0.0.1:5000',
	// API_BASE_URL: 'http://localhost:5000',
	GOOGLE_MAP_API: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
	// GOOGLE_MAP_API: 'AIzaSyDWYgiG4NUE8G2rxdOnDJbhSDV0u_Wf5v8',
}

const DEV = {
	FIREBASE_API_KEY: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
	FIREBASE_AUTH_DOMAIN: 'trackit-954bb.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://trackit-954bb.firebaseio.com',
	FIREBASE_PROJECT_ID: 'trackit-954bb',
	FIREBASE_STORAGE_BUCKET: 'trackit-954bb.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '799165776336',

	BASE_URL: 'https://d1gma93xo1rsqh.cloudfront.net',
	API_BASE_URL: 'https://xgng24cr5l.execute-api.us-east-1.amazonaws.com/dev',
	// API_BASE_URL: 'http://127.0.0.1:5000',
	GOOGLE_MAP_API: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
	// GOOGLE_MAP_API: 'AIzaSyDWYgiG4NUE8G2rxdOnDJbhSDV0u_Wf5v8',
}

const PROD = {
	FIREBASE_API_KEY: 'AIzaSyC10oErT_m8i0WLcDFlxPmiEwFslLSoU-4',
	FIREBASE_AUTH_DOMAIN: 'trackit-prod-8dd1b.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://trackit-prod-8dd1b.firebaseio.com',
	FIREBASE_PROJECT_ID: 'trackit-prod-8dd1b',
	FIREBASE_STORAGE_BUCKET: 'trackit-prod-8dd1b.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '411148091305',

	BASE_URL: 'https://www.trackittechnology.com/',
	API_BASE_URL: 'https://tsk24c4mk8.execute-api.us-east-1.amazonaws.com/prod',
	// API_BASE_URL: 'http://127.0.0.1:5000',
	GOOGLE_MAP_API: 'AIzaSyC10oErT_m8i0WLcDFlxPmiEwFslLSoU-4',
	// GOOGLE_MAP_API: 'AIzaSyDWYgiG4NUE8G2rxdOnDJbhSDV0u_Wf5v8',
}

function determine_dev() {
	const stagingURLArray = [
		'https://d1gma93xo1rsqh.cloudfront.net',
		'http://staging.trackittechnology.com.s3-website-us-east-1.amazonaws.com',
		'https://staging.trackittechnology.com',
	]
	return (
		stagingURLArray.includes(window.location.origin) || process.env.NODE_ENV === 'development'
	)
}

export default {
	// dev: false,
	dev: determine_dev(),
	// apiDebugging: true,
	apiDebugging: determine_dev(),
	isLocal: window.location.origin.includes('localhost'),

	VERSION: '2.1.5',

	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	...DEV,
	...PROD,
	...(determine_dev() ? (window.location.origin.includes('localhost') ? LOCAL : DEV) : PROD),
}
