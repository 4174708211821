export const customTableFilter = (filter, row) => {
	//TODO: Update and write tests
	const id = filter.pivotId || filter.id
	const rowValue = row[id]

	if (rowValue !== null && (typeof rowValue === 'string' || typeof rowValue === 'number')) {
		return row[id] !== undefined
			? String(rowValue).toLowerCase().includes(filter.value.toLowerCase())
			: true
	}
}

export const filterDateColumn = (dateFormatFunction, filter, row) => {
	//TODO: Update and write tests
	const id = filter.pivotId || filter.id
	const rowValue = row[id]

	if (rowValue) {
		const dateString = dateFormatFunction(rowValue)
		return dateString.toLowerCase().includes(filter.value.toLowerCase())
	}
}

export const filterCustomColumn = (customValue, filter) => {
	if (customValue) {
		return String(customValue).toLowerCase().includes(filter.value.toLowerCase())
	}
}
