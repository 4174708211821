import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
// import { TEPrivateRoute } from 'react-tec'

// import Front from 'layouts/Front/ContentWrapper'
const Admin = lazy(() => import('layouts/Admin/ContentWrapper'))
const Portal = lazy(() => import('layouts/Portal/ContentWrapper'))
const Track = lazy(() => import('layouts/Track/ContentWrapper'))
const Front = lazy(() => import('layouts/Front/ContentWrapper'))
const MobileMenu = lazy(() => import('layouts/MobileMenu/ContentWrapper'))

const App = (props) => {
	return (
		<Suspense fallback={<div />}>
			<Switch>
				<Route path='/admin/' component={Admin} />
				<Route path='/track/' component={Track} />
				<Route path='/portal/' component={Portal} />
				<Route path='/mobile/' component={MobileMenu} />
				<Route path='/' component={Front} />
			</Switch>
		</Suspense>
	)
}

export default App
