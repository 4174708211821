export const theme = {
	primary: '#0046FB',
	white: '#FFFFFF',
	black: '#1e1e1e',
	darkGray: '#1C1C1C',
	red: '#ff0000',
	green: '#00b400',
	errorRed: '#fb0046', // triadic color of lightBlue theme

	// cms
	darkBlue: '#0A2149',
	lightBlue: '#0046FB',
	neonGreen: '#0FFD66',
	lightGray: '#BCBCBC',
	lighterGray: '#F4F4F4',
}
export const globalStyles = `
	body {
		margin: 0px;
		padding: 0px;
		font-family: 'Montserrat';
		color: ${theme.lightBlue};
	}
	.TEpanelTitleTitle {
		font-style: italic;
	}
	.TEButton {
		color: white;
		font-size: 12px;
		font-family: 'Montserrat';
		color: #0046fb;
		border-color: #0046fb;
	}
	.TEButton:hover {
		background-color: #0046fb;
		color: #0ffd66;
		border-color: #0046fb;
	}
	.TEButton:active {
		background-color: #0046fb;
		color: #0ffd66;
		border-color: #0046fb;
	}
	.TEPopupFormLeftButton {
		background-color: #f4f4f4;
	}
`
