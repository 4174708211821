import toImg from 'react-svg-to-image'

export const downloadQR = (filePrefix) => {
	toImg('#svg', `${filePrefix}-QRCode`, { scale: 7, format: 'png', download: true })
}

export const parseTweetId = (tweetUrl) => {
	try {
		const strings = tweetUrl.split('/')
		const possibleId = strings[strings.length - 1]
		return possibleId
	} catch (err) {
		console.log(err)
	}
}

export const parseUrlParams = (url, scriptArray, scriptGroupArray) => {
	// no longer supporting both script and script group in the query params
	const query = new URLSearchParams(url)
	if (query.get('script')) {
		const script = scriptArray.find((el) => el.uid === query.get('script'))
		return { s: script, sg: undefined }
	} else if (query.get('scriptgroup')) {
		const scriptGroup = scriptGroupArray.find((el) => el.uid === query.get('scriptgroup'))
		return { s: undefined, sg: scriptGroup }
	} else {
		return { s: undefined, sg: undefined }
	}
}

export const redirectWithParams = (history, script, scriptGroup, urlstr, moreParams = '') => {
	const url = getRedirectLink(script, scriptGroup, urlstr, (moreParams = ''))
	history.push(url)
}

export const getRedirectLink = (script, scriptGroup, urlstr, moreParams = '') => {
	// no longer supporting both script and script group in the query params
	const query = new URLSearchParams(moreParams)
	if (script) {
		query.append('script', script.uid)
	} else if (scriptGroup) {
		query.append('scriptgroup', scriptGroup.uid)
	}
	if (query.toString()) {
		return `${urlstr}?${query.toString()}`
	}
	return urlstr
}

export const isScheduled = (pageOrHighlightWithMD) => {
	const ALWAYS = 1
	const WEEKDAY = 2
	const WEEKEND = 3

	// console.log(pageOrHighlightWithMD?.schedule, { pageOrHighlightWithMD })

	const currentTimeInsideRange = (schedule_start, schedule_end) => {
		const now = new Date()
		const start = new Date(schedule_start)
		const end = new Date(schedule_end)
		// normalize dates
		start.setFullYear(now.getFullYear(), now.getMonth(), now.getDate())
		end.setFullYear(now.getFullYear(), now.getMonth(), now.getDate())
		if (now >= start && now < end) {
			return true
		} else {
			return false
		}
	}

	const { active, active_start, active_end } = pageOrHighlightWithMD
	if (active === undefined || active === false) {
		return false
	}
	if (active_start) {
		// if undefined, assume page is active
		const now = Date.now()
		if (now < active_start) {
			return false
		}
	}
	if (active_end) {
		// if undefined, assume page is active
		const now = Date.now()
		if (now >= active_end) {
			return false
		}
	}

	const { schedule, schedule_start, schedule_end } = pageOrHighlightWithMD
	const today = new Date().getDay()
	switch (schedule) {
		case ALWAYS:
			if (schedule_start && schedule_end) {
				return currentTimeInsideRange(schedule_start, schedule_end)
			} else {
				// if no scheduled times return true
				return true
			}
		case WEEKDAY:
			if (today === 0 || today === 6) {
				return false
			} else {
				if (schedule_start && schedule_end) {
					return currentTimeInsideRange(schedule_start, schedule_end)
				} else {
					// if no scheduled times return true
					return true
				}
			}
		case WEEKEND:
			if (today === 0 || today === 6) {
				if (schedule_start && schedule_end) {
					return currentTimeInsideRange(schedule_start, schedule_end)
				} else {
					// if no scheduled times return true
					return true
				}
			} else {
				return false
			}
		default:
			return false
	}
}
