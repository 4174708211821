export const triangleMarkBorders = (size, color) => {
	return `
		border-top: ${size}px solid ${color};
		border-left: ${size}px solid ${color};
		border-right: ${size}px solid transparent;
		border-bottom: ${size}px solid transparent;
	`
}
export const triangleMarkBordersUnitLess = (size, color) => {
	return `
		border-top: ${size} solid ${color};
		border-left: ${size} solid ${color};
		border-right: ${size} solid transparent;
		border-bottom: ${size} solid transparent;
	`
}

export const colorBetweenColors = (color1, color2, percBetween) => {
	var hex = function(x) {
		x = x.toString(16)
		return x.length === 1 ? '0' + x : x
	}
	const color1RGB = hexToRgb(color1)
	const color2RGB = hexToRgb(color2)

	const r = Math.ceil(color1RGB.r * percBetween + color2RGB.r * (1 - percBetween))
	const g = Math.ceil(color1RGB.g * percBetween + color2RGB.g * (1 - percBetween))
	const b = Math.ceil(color1RGB.b * percBetween + color2RGB.b * (1 - percBetween))

	return '#' + hex(r) + hex(g) + hex(b)
}
const hexToRgb = (hex) => {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	hex = hex.replace(shorthandRegex, function(m, r, g, b) {
		return r + r + g + g + b + b
	})

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null
}
